
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Lato:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800&subset=latin,latin-ext);


// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.row {
	margin-top:10px;
}

.container {
  margin: 0 auto;
  width: 100%;
} 


html body {
    background-color: white;
  font-family: 'Lato', 'Tahoma', sans-serif;
}


a.vehicleSliderLeft {
    float: left;
    position: fixed;
    top: 42%;
    left:5%;
}    

a.vehicleSliderRight {
    float: right;
    position: fixed;
    top: 42%;
    right:5%;
}



#tabela {
   border: 1px solid black;
   border-collapse: collapse;
   border-color: #6d2515;
   color: #6d2515;
}

#tabela td {
   border: 1px solid black;
   padding: 5px;
   border-color: #6d2515;
   color: #6d2515;
}

#tabela th {
   border: 1px solid black;
   padding: 5px;
   border-color: #6d2515;
   color: #6d2515;
}

.left{
  width: 60px;
  height: 60px;
  background-color: #dddddd;
  border-radius: 100%;
  margin-left:-30px;
  z-index:99;
}

.right {
  width: 60px;
  height: 60px;
  background-color: #dddddd;
  border-radius: 100%;
  margin-right:-30px;
  z-index:99;
}

.left:hover{
  width: 60px;
  height: 60px;
  background-color: #72c02c;
  border-radius: 100%;
  margin-left:-30px;
  z-index:99;
}

.right:hover {
  width: 60px;
  height: 60px;
  background-color: #72c02c;
  border-radius: 100%;
  margin-right:-30px;
  z-index:99;
}

//loader color
.ball-pulse > div {
  background: black;
}


.search {
  color:#dddddd;
}

.search:focus {
  color:black;
}

.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e4e9f0;
}

.headline h2 {
  font-size: 22px;
}

.headline h2, 
.headline h3, 
.headline h4 {
  margin: 0 0 -2px 0;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid #72c02c;
}

.headline-md {
  margin-bottom: 15px;
}

.headline-md h2 {
  font-size: 21px;
}

.scrollToTop{
  width: 60px;
  height: 60px;
  background: #dddddd;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  text-align:center; 
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  position:fixed;
  bottom:120px;
  right:60px;
  display:none;
  font-size:25px;
  padding-top:8px;
}

.scrollToTop:hover{
  background: #72c02c;
  text-decoration: none;
}

.btn-success {
  color: #ffffff;
  background-color: #72c02c;
  border-color: #72c02c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-default {
  color: #FFFFFF;
  background-color: #72c02c;
  border-color: #72c02c;
}

div.row-hover {
  padding: 15px 15px;
  border-top: solid 1px #dddddd;
  background: #ffffff;
}

div.row-hover:hover {
  padding-bottom:-15px;
  border-top: solid 1px #dddddd;
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(252,252,252,1) 52%, rgba(250,250,250,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(252,252,252,1) 52%,rgba(250,250,250,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(252,252,252,1) 52%,rgba(250,250,250,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fafafa',GradientType=1 ); /* IE6-9 */

}

.border-radius {
 border-radius: 5px; 
}


/*search row*/
.rowsearch {
	padding: 5px;
}

/*photo on search*/
.photosearch {
	margin:5px;
	width: 200px;
}

/*photo on search*/
.photoshow {
	margin:5px;
	padding:10px;
	width: 270px;
}

/*background row search*/
.bgsearch {
	background: #FAFAFA;
}

footer {
	padding-top:45px;
	margin-bottom:25px;
}

.smallhr {
	margin-top:3px;
	margin-bottom:3px;
	padding-left:20px;
	padding-right:30px;
}

label{
	padding-left:20px;
}

.endprice {
	font-weight:800;
	font-size:26px;
	margin-top:-5px;
  color:#000000;
}